.instructions-list {
    width: 40%;
    border-radius: 8px;
    border: 5px solid rgb(23, 23, 23);
    padding: 10px;
    margin-top: 10px;
    padding: 0 10px;
}
.instruction {
    margin: 10px;
    letter-spacing: 0.5px;
    color: black;
    font-weight: 400;
    margin-top: 20px;
}

.instructions-container {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

.pose-demo-img {
    height: 400px;
    aspect-ratio: 1;
    border-radius: 15px;
}