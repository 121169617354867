/* DropDown Component */
.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #3a3b3c;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dropdown-toggle:hover {
    background-color: #4a4b4c;
  }
  
  .dropdown-custom-menu {
    background-color: #2f2f2f;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 0;
  }
  
  .dropdown-item-container {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: all 0.3s ease;
  }
  
  .dropdown-item-container:hover {
    background-color: #424242;
  }
  
  .dropdown-item-1 {
    color: #ffffff;
    font-size: 16px;
    flex-grow: 1;
  }
  
  .dropdown-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 10px;
  }
  