body, html {
  margin: 0;
  padding: 0;
  
}
body{
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

}
