
.home-header {
    background-color: rgba(147, 202, 221, 0.705);
    backdrop-filter: blur(10px);
    margin: auto;
    width: 90vw;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.119);
    border-radius: 10px;
}
.home-container {
   
}
.header-btn:hover {
    border-color: black;
}

.home-heading {
    padding: 0px;
    margin: 0px;
    font-size: 44px;
    margin-left: 30px;
    color: black;
    font-family: 'Kaushan Script', cursive;
}

.home-main {
    margin: auto;
    margin-top: -50px;
    width: 100vw;
    /* background:  url('../../utils/images/yoga_pose.png') ; */
    background-image: url('../../utils/images/yoga-bg.png');
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size:  700px;
}

.description {
    font-size: 66px;
    background-image: linear-gradient(-20deg, #230538 0%, #5219a8 50%, #be86e6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    margin: 0px;
    margin-top: 10px;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0.5px;
    animation: gradientAnimation 2s ease infinite;
    background-size: 200% 200%;
}

/* Gradient animation */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.btn-section {
    position: absolute;
    left: 38vw;
    top: 60vh;
}

.btn{
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 10px 20px 10px 20px;
    color: black;
    font-size: 24px;
    border-color: #4a1e96;
    background-color: rgba(171, 171, 171, 0.629);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border-width: 4px;
    cursor: pointer;
}
.btn:hover {
    background: rgba(255,165,0, 0.2);
    color: black;
}

body::-webkit-scrollbar {
    display: none;
}
#about-btn {
    padding: 5px 10px;
}
#about-btn:hover {
    border-color: black;
    background-color: black;
    color: black;
}
#about-btn:focus {
    box-shadow: none;
    background: white;
    border-color: black;
    color: black;
}