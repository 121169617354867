.tutorials-container {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.tutorials-heading {
    font-size: 54px;
    margin-left: 100px;
    padding-top: 50px;
    color: black;
    font-family: 'Raleway', sans-serif;
}

.tutorials-content {
    color: black;
    font-size: 18px;
    margin: auto;
    margin-left: 100px;
    margin-top: 4px;
}
.tutorials-content-container {
    width: 70%;
}