.about-container {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.about-heading {
    font-size: 54px;
    text-align: center;
    padding-top: 10px;
    color: black;
    font-family: 'Raleway', sans-serif;
}
.about-main {
    margin: auto;
    width: 70%;
}
.about-content {
    color: black;
    font-size: 18px;
}
.developer-info h4 {
    color: black;
    font-family: 'Raleway', sans-serif;
    margin-top: 20px;
    text-decoration: underline;
}
.about-content a,
.developer-info a {
 color: black;  
}